body {
  margin: 0;
  font-family: 'Chelsea Market', cursive;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
h1, h2, h3, h4, h5, h6{
  font-family: 'Chelsea Market', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

p{
  font-family: 'Chelsea Market', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 0 14px;
}

html{
  overflow-y: scroll;
  height: 100vh; 
  scroll-snap-type: y mandatory;
  scrollbar-width: none;
  -ms-overflow-style: none;
  scroll-behavior: smooth;
  -webkit-scrollbar {
    display: none; 
  }

}
@media all and (max-width: 1049px){
  html{
    scroll-snap-type: none;
  }
}

.scrolling-text-container {
  width: 100%;
  padding: 4px 0;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}

.scrolling-text {
  display: inline-block;
  color: #c19203;
  animation: scroll-text 120s linear infinite;
}
.scrolling-text span{
  margin-left: 20px;
}

@keyframes scroll-text {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes scroll-text {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}